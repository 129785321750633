import React, { useState } from "react";
import { Input } from "@mui/material";
import { useGetAppVersions, useUpdateAppVersion } from "../services/appVersion";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";

export const AppSettingsList = () => {
  const [appVersion, setAppVersion] = useState({
    qa_env_restaurants_app_version: "",
    staging_env_restaurants_app_version: "",
    production_env_restaurants_app_version: "",
  });
  const { data, isLoading } = useGetAppVersions({
    refetchOnFocus: true,
    onSuccess: (res) => {
      setAppVersion(res);
    },
  });
  const { mutate } = useUpdateAppVersion();

  const handleSaveSettings = () => {
    mutate(appVersion, {
      onSuccess: () => {
        toast.success("Versiones de aplicación actualizadas correctamente");
      },
      onError: () => {
        toast.error("Algo sucedió");
      },
    });
  };

  const handleInputChange = (prop: string, change) => {
    setAppVersion({ ...appVersion, [prop]: change.target.value });
  };
  const generateEnvironmentsInputs = () => {
    const appEnvs = [
      "qa_env_restaurants_app_version",
      "staging_env_restaurants_app_version",
      "production_env_restaurants_app_version",
    ];

    return appEnvs.map((cur) => (
      <>
        <h5>{cur}</h5>
        <Input
          defaultValue={data[cur]}
          onInput={(change) => handleInputChange(cur, change)}
        />
      </>
    ));
  };

  if (isLoading) {
    return <>Cargando...</>;
  }

  return (
    <div style={{ margin: 30, display: "flex", flexDirection: "column" }}>
      <h3>Versiones de la aplicación</h3>
      {generateEnvironmentsInputs()}
      <Button onClick={handleSaveSettings}>GUARDAR</Button>
      <p>API URL:</p>
      <p>{process.env.REACT_APP_API_URL}</p>
    </div>
  );
};
